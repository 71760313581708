import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import gtm1 from '../images/GTM_Images-1.jpg'
import gtm2 from '../images/GTM_Images-2.jpg'
import gtm3 from '../images/GTM_Images-3.jpg'
import gtm4 from '../images/GTM_Images-4.jpg'
import gtm5 from '../images/GTM_Images-5.jpg'
import gtm6 from '../images/GTM_Images-6.jpg'
import gtm7 from '../images/GTM_Images-7.jpg'
import gtm8 from '../images/GTM_Images-8.jpg'


export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="Google Tag Manager is a tricky tool but can provide exceptional insight for your business. Read our step-by-step guide now."></meta>
          <title>Conversion Tracking with GTM | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/GTMConversionSetup" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="gtm--title">GTM Conversion Setup</h1>

      <div className="gtm">

      <div className="gtm_block">
            <h2 className="gtm_block--title">How to set up a conversion in Google Tag Manager, Ads, Analytics</h2>
            <p className="gtm_block--text">
            Almost everyone knows how to “Google” something, but using the other tools Google offers, like Google Tag Manager, can be a bit tricky.
            </p>
        </div>

        <div className="gtm_block">
            <h2 className="gtm_block--title">What is Google Tag Manager (GTM)?</h2>
            <p className="gtm_block--text">
            <p>Google Tag Manager is a tool that allows businesses to gain a clearer view on how people interact with their website.</p>
            <p>Through GTM you can create custom tags, which are simply snippets of code that you can then place throughout your site to monitor many different interactions. The most common purposes that tags are used for are monitoring form submissions, button clicks, how people arrive at your site, items being added or removed from the shopping cart, etc.</p>
            <p>All businesses are driven by data, and with GTM you are able to collect enormous amounts of essential customer data without ever having to actually work within the source code of your site.</p>
            <p>Below are step-by-step instructions for setting up different types of tags for a Page View Conversion and a Link/Button Click conversion.</p>
            </p>
        </div>

        <div className="gtm_block">
            <h2 className="gtm_block--title">Page View Conversion</h2>
            <p className="gtm_block--text">
            <p className="gtm_block--subtitle">In Google Ads</p>
            <ul><li>Go to Tools & Settings → Conversions → Blue plus button in top left</li></ul>
            </p>
            <img src={gtm1} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>


        <div className="gtm_block">
            <p className="gtm_block--text">
            <ul><li>Click Website → Category section select “Page View” → Value section select “Don’t use a value for this conversion action” (You can decide what to put here) → Count section select “Every” → Conversion Window section leave on “30 Days” → View Through Conversion Window section select “30 Days” → Include in Conversions section leave on “Yes” → Attribution Model section select “Linear” (You can decide what to put here, whatever fits the campaign best) → Click “Create and Continue”</li></ul>
            </p>
            <img src={gtm2} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>

        <div className="gtm_block">
            <p className="gtm_block--text">
            <ul><li>Tag Set-Up section → Select “Use Google Tag Manager” → Leave this page open, you will later use the Conversion ID and Conversion Label</li></ul>
            </p>
            <img src={gtm3} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>

        <div className="gtm_block">
            <p className="gtm_block--text">
            <p className="gtm_block--subtitle">In GTM</p>
            <ul><li>Go to Tags → New → Name the tag to relate to the conversion you will set up</li></ul>
            <ul><li>Tag Configuration → Select "Google Ads Conversion Tracking"</li></ul>
            <ul><li>Go Back into the Google Ads page and grab the Conversion ID and Conversion Label to fill in the Conversion ID and Conversion Label sections in GTM</li></ul>
            </p>
            <img src={gtm4} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>

        <div className="gtm_block">
            <p className="gtm_block--text">
            <ul><li>Triggering → Click the blue plus in top right → Name the trigger the same as the conversion name</li></ul>
            <ul><li>Trigger Configuration → Select “Page View” → Select “Some Page Views” → Change the default “Click Classes” to “Page URL” → “Contains” → Go into website and copy the back half of the URL of the page you want the tag to fire on and paste it into the third box (example: /home-care-self-evaluation) → Save → Save</li></ul>
            </p>
            <img src={gtm5} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>

        <div className="gtm_block">
            <p className="gtm_block--text">
            <ul><li>In Preview Mode → Refresh in GTM→ Refresh on the site → Make sure the tag is firing → Back in Google Ads, make sure you hit Next then Done on the page you left open with the Conversion ID and Conversion Label</li></ul>
            </p>
        </div>

        <div className="gtm_block">
        <h2 className="gtm_block--title">Link/Button Click Conversion</h2>
            <p className="gtm_block--text">
            <p className="gtm_block--subtitle">In GTM</p>
            <ul><li>Go To Tags → New → Name the tag to relate to the conversion you will set up</li></ul>
            <ul><li>Tag Configuration → Select "Google Analytics: Universal Analytics"</li></ul>
            <ul><li>Track Type → Select "Event"</li></ul>
            <ul><li>Category section write “Link” → Action section write “Click” → Label section write the name of the conversion with no spaces</li></ul>
            <ul><li>Check the “Enable overriding settings in this tag” box → Go into the clients Analytics acct. and copy the Tracking ID and place it into the Tracking ID section</li></ul>
            </p>
            <img src={gtm6} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>

        <div className="gtm_block">
            <p className="gtm_block--text">
            <ul><li>Triggering → Click the blue plus in top right → Name the trigger the same as the conversion</li></ul>
            <ul><li>Trigger Configuration → Select “All Elements” → Select “Some Clicks”</li></ul>
            <ul><li>Go into the website → with the debug window open, click on the button you want to track → click on “Variables” → Find 2 or 3 variables unique to this button click (example: /services/personalized-therapy-wellness-programs/specialty-care/ ) → Back in GTM, in the first box fill in the type of variable it is → second box, select “contains” → third box, paste the unique value you are using from the site → Save</li></ul>
            </p>
            <img src={gtm7} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
        </div>

        <div className="gtm_block">
            <p className="gtm_block--text">
            <ul><li>Refresh in GTM → Refresh in the Website → Make sure your tag is firing</li></ul>
            <p className="gtm_block--subtitle">In Analytics</p>
            <ul><li>Go to Admin → Goals → New Goal</li></ul>
            <ul><li>Select “Custom” → Name the goal the same as in GTM → Select “Event”</li></ul>
            <ul><li>In the Goals Details Section → Fill out the Category, Action, and Label boxes the EXACT same as what you put in GTM (If anything is different it won’t track)</li></ul>
            </p>
            <img src={gtm8} className="gtm--image" alt="GTM Conversion Setup | WRAL Digital Solutions"/>
            <p className="gtm_block--text">
            <ul><li>And finally, save!</li></ul>
            </p>
        </div>

        <div className="creative_banner">
            <h3 className="creative_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="creative_cta">
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
  );
